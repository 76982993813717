/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-16 18:19:55
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-17 16:46:00
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listCustomerPayInfo = params => axios({
    url: '/api/dealer/dealer/dealerPayInfo/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})


export const listOnlyIntegral = params => axios({
  url: '/api/dealer/dealer/dealerPayInfo/listOnlyIntegral',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const addCustomerPayInfo = params => axios({
    url: '/api/dealer/dealer/dealerIntegralRecord/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editCustomerPayInfo = params => axios({
    url: '/api/dealer/dealer/dealerPayInfo/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delCustomerPayInfo = params => axios({
    url:'/api/customer_pay_info/system/customerPayInfo/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdCustomerPayInfo = params => axios({
    url: '/api/dealer/dealer/dealerPayInfo/selectIntegralVOByDealerId/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
